@use 'sass/reset'
@import 'sass/variables'

// Fonts
// $primary-font: Arial
// $headings-font: Helvetica

// Colors

body
  font-family: $primary-font

h1, h2, h3, h4, h5
  font-family: $headings-font


.section
  &__inner
    max-width: 1440px
    margin-left: auto
    margin-right: auto
